import './App.css';
import CommingSoon from './components/commingSoon/CommingSoon';

function App() {
  return (
    <div className="App">
      <CommingSoon />
    </div>
  );
}

export default App;
