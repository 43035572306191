import React from "react";
import { Grid } from "@mui/material";
import './CommingSoon.css';
export default function CommingSoon() {
  return (
    <div className="section">
      <Grid lg={12} xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="main"
      >
        <Grid item lg={6} xs={8}   justifyContent="center"
        alignItems="center" textAlign='center'>
           <img   src="../images/Frametop.svg" alt="" style={{zIndex:"10",width:"100%"}} className="responsive" />
        </Grid>

      </Grid>
    </div>
  );
}
